import React from 'react';
import { FaArrowDown } from 'react-icons/fa';
import styled from 'styled-components/macro';
import Container from '../components/container';
import Layout from '../components/layout';
import HeroBannerImage from '../images/hero.svg';

const HeroBannerContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
`;

const HeroBannerBodyContainer = styled(Container)`
  position: relative;
  width: 100%;
  background: rgb(42, 177, 245);
  background: linear-gradient(
    180deg,
    rgba(42, 177, 245, 1) 0%,
    rgba(4, 114, 173, 1) 100%
  );
`;

const HeroWavyBackground = styled.img`
  width: 100%;
  object-fit: contain;
`;

const HeroBanner = styled.div`
  width: 100%;
  max-width: calc(1280px + 8rem);
  padding: 2rem 4rem;
  padding-bottom: 0rem;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const HeroBannerTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 16rem;

  h1 {
    color: white;
    font-family: 'Brandon Grotesque';
    font-size: 3.6rem;
  }

  p {
    color: white;
    font-size: 1.15rem;
  }
`;

const HeroBannerImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 2;

  img {
    width: 100%;
  }
`;

const ArrowDownLink = styled.a`
  color: white;
  position: absolute;
  bottom: -3rem;
  font-size: 2rem;
`;

const GroupSection = styled.section`
  width: 100%;
  max-width: calc(1280px + 8rem);
  padding: 2rem 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const SectionTitle = styled.h2`
  font-size: 3.6rem;
  font-weight: bold;
  font-family: 'Brandon Grotesque';
  color: #09161e;
`;

const GroupDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const GroupDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }

  img {
    background-color: white;
    object-fit: contain;
    height: 13rem;
  }

  p {
    font-size: 1.05rem;
  }
`;

const CaseStudiesSection = styled.section`
  width: 100%;
  max-width: calc(1280px + 8rem);
  padding: 2rem 4rem;

  ${SectionTitle} {
    text-align: left;
    margin-bottom: 4rem;
  }
`;

const CaseStudiesGroupContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const CaseStudy = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }

  img {
    background-color: white;
    width: 100%;
    height: 15rem;
    object-fit: contain;
  }

  h3 {
    text-align: left;
    color: #5d5d5d;
    font-family: 'Brandon Grotesque';
    font-size: 0.9rem;
    opacity: 0.86;
    font-weight: 500;
    margin: 0.5rem 0;
  }

  p {
    font-size: 1.1rem;
    font-weight: bold;
    color: #09161e;
  }
`;

const ContactContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
`;

const ContactWavyBackground = styled.img`
  width: 100%;
  object-fit: contain;
`;

const ContactBodyContainer = styled(ContactContainer)`
  width: 100%;
  background: rgb(87, 185, 233);
  background: linear-gradient(
    180deg,
    rgba(87, 185, 233, 1) 0%,
    rgba(102, 162, 216, 1) 100%
  );
`;

const ContactSection = styled.section`
  width: 100%;
  max-width: calc(1280px + 8rem);
  padding: 2rem 4rem;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ContactTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;

  h2 {
    font-family: 'Brandon Grotesque';
    font-size: 3.6rem;
    color: #fdfdfe;
    font-weight: bold;
  }

  p {
    color: #fdfdfe;
  }
`;

const ContactForm = styled.form`
  flex: 3;
  margin-left: 8rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-left: 0;
  }

  input,
  textarea {
    padding: 1rem;
    border-radius: 10px;
    border: 0;
    margin-bottom: 1rem;
    resize: none;
    width: 100%;
  }

  textarea {
    height: 13rem;
  }

  button {
    background-color: #04608e;
    color: white;
    border-radius: 10px;
    padding: 0.8rem 0;
    border: 0;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const IndexPage = () => (
  <Layout>
    <HeroBannerContainer>
      <HeroBannerBodyContainer>
        <HeroBanner>
          <HeroBannerTextContainer>
            <h1>Designing at the core</h1>
            <p>
              Archmob is a group of companies and independent consultants and
              designers focused on delivery of solutions and design services
            </p>
          </HeroBannerTextContainer>
          <HeroBannerImageContainer>
            <img src={HeroBannerImage} alt="" />
          </HeroBannerImageContainer>
        </HeroBanner>
        <ArrowDownLink href="#our-group">
          <FaArrowDown></FaArrowDown>
        </ArrowDownLink>
      </HeroBannerBodyContainer>
      <HeroWavyBackground src={require('../images/hero-wavy.png')} alt="" />
    </HeroBannerContainer>
    <Container>
      <GroupSection id="our-group">
        <SectionTitle>Our Group</SectionTitle>
        <GroupDescriptionContainer>
          {[
            {
              icon: require('../images/group-1.png'),
              description:
                'Education Suite and Solutions offers a number of mobile apps centered on primary education and assistive communication.',
            },
            {
              icon: require('../images/group-2.png'),
              description:
                'Design Group provides expertise on UX and UI Design Requirements that are based on user research and validation.',
            },
            {
              icon: require('../images/group-3.png'),
              description:
                'SME and Startup Solutions provides tools, products and business integrations for e-commerce and mobile applications to enhance business and your customer’s experience.',
            },
          ].map(group => (
            <GroupDescription key={group.description}>
              <img src={group.icon} alt="" />
              <p>{group.description}</p>
            </GroupDescription>
          ))}
        </GroupDescriptionContainer>
      </GroupSection>
    </Container>
    <Container>
      <CaseStudiesSection id="references">
        <SectionTitle>References</SectionTitle>
        <CaseStudiesGroupContainer>
          {[
            {
              icon: require('../images/case-study-1.png'),
              title: 'Tomptaps',
              description:
                'Designed, developed and launched the assistive tool for kids with special needs',
            },
            {
              icon: require('../images/case-study-2.png'),
              title: 'Detecthink',
              description:
                'A project to design and develop the Math app through a mobile detective game',
            },
            {
              icon: require('../images/case-study-3.png'),
              title: 'Design',
              description:
                'User Experience Consultancies and Rebranding considerations',
            },
          ].map(caseStudy => {
            return (
              <CaseStudy key={caseStudy.title}>
                <img src={caseStudy.icon} alt={caseStudy.title} />
                <h3>{caseStudy.title}</h3>
                <p>{caseStudy.description}</p>
              </CaseStudy>
            );
          })}
        </CaseStudiesGroupContainer>
      </CaseStudiesSection>
    </Container>
    <ContactContainer id="contact">
      <ContactWavyBackground
        src={require('../images/contact-wavy.png')}
        alt=""
      />
      <ContactBodyContainer>
        <ContactSection>
          <ContactTextContainer>
            <h2>Start something great</h2>
            <p>
              Do you want to customize a product or do you have something else
              in mind? Give us a shout out.
            </p>
          </ContactTextContainer>
          <iframe
            title="redirect"
            name="redirect"
            id="redirect"
            onLoad={() => {
              alert('Submission successful!');
              document.getElementById('email').value = '';
              document.getElementById('message').value = '';
            }}
            style={{ display: 'none' }}
          ></iframe>
          <ContactForm
            action="https://docs.google.com/forms/d/e/1FAIpQLSf7LagyjrtB2liV_Z1XasmDEbvjYIAiJ6AjbIRswFjnyMVYNA/formResponse"
            method="POST"
            target="redirect"
          >
            <input
              id="email"
              type="email"
              name="entry.1603471485"
              placeholder="Your email address"
              required
            />
            <textarea
              id="message"
              name="entry.1330718337"
              placeholder="Write something here"
              required
            ></textarea>
            <button type="submit">SUBMIT</button>
          </ContactForm>
        </ContactSection>
      </ContactBodyContainer>
    </ContactContainer>
  </Layout>
);

export default IndexPage;
